<template>
  <div>
    <v-file-input
      @change="selectFile"
      :loading="loadingFile"
      :rules="rules"
      :key="key"
      v-bind="$attrs"
      truncate-length="30"
      show-size
    ></v-file-input>
    <v-btn
      @click="uploadFile"
      :disabled="isFileSizeExceeded || loadingFile || !file"
      color="primary"
      class="ma-2 white--text"
      x-small
    >
      Завантажити
      <v-icon right dark> mdi-cloud-upload </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FileUploader',

  emits: ['updateUpload', 'encode'],

  props: {
    actionType: {
      type: String,
      required: false,
      default: '',
    },
    companyId: {
      required: false,
      default: null,
    },
    partnerId: {
      required: false,
      default: null,
    },
    articleId: {
      required: false,
      default: null,
    },
    firmwareId: {
      required: false,
      default: null,
    },
    userId: {
      required: false,
      default: null,
    },
    returnBinary: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rules: [(value) => !value || value.size < 2000000 || 'Розмір файлу не повинен перевищувати 2 Мб!'],
    file: null,
    loadingFile: false,
    key: 0,
  }),

  computed: {
    isFileSizeExceeded() {
      return this.file && this.file.size > 2000000
    },
  },

  methods: {
    ...mapActions('profile', ['uploadProfileLogo']),
    ...mapActions('admin', ['uploadUserLogo']),
    ...mapActions('companies', ['uploadCompanyLogo']),
    ...mapActions('partners', ['uploadPartnerLogo']),
    ...mapActions('firmwares', ['uploadFirmwareFile']),

    selectFile(file) {
      this.file = file
    },
    async uploadFile() {
      if (this.returnBinary) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const arrayBuffer = event.target.result
          const uint8Array = new Uint8Array(arrayBuffer)
          let binaryString = ''
          for (let i = 0; i < uint8Array.length; i++) {
            binaryString += String.fromCharCode(uint8Array[i])
          }
          const base64String = btoa(binaryString)
          this.$emit('encode', base64String)
        }
        reader.readAsArrayBuffer(this.file)
        return
      }
      try {
        this.loadingFile = true
        const formData = new FormData()
        formData.append('file', this.file)
        const payload = {
          id: this.companyId,
          partnerId: this.partnerId,
          articleId: this.articleId,
          firmwareId: this.firmwareId,
          userId: this.userId,
          formData: formData,
        }
        await this[this.actionType](payload)
        this.key++
        if (this.companyId || this.partnerId || this.articleId || this.firmwareId || this.userId) this.$emit('updateUpload')
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingFile = false
      }
    },
  },
}
</script>

<style scoped></style>
